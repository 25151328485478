/***************************************************************************************************
 * Load `$localize` onto the global scope - used if i18n tags appear in Angular templates.
 */
import '@angular/localize/init';
/***************************************************************************************************
 * BROWSER POLYFILLS
 */

/** .toBlob polyfill for IE and other old browsers**/
import './canvasToBlob/canvas-toBlob.js';

/** OffscreenCanvas polyfill for Firefox */
import './offscreenCanvas/offscreen-canvas.js';

/*****************************************************************************************************
 * Other imports
 */

import '../zone-flags';

/***************************************************************************************************
 * Zone JS is required by default for Angular itself.
 */

import 'zone.js'; // Included with Angular CLI.

(window as any).global = window;
